import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 解决导航沉余的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  //首页
  {
    path: "/",
    name: "homeIndex",
    component:()=> import('@/views/homeIndex.vue'),
    meta: {
      title:'首页'
    }
  },
  //登录
  {
    path: "/login",
    name: "login",
    component:()=> import('@/views/login.vue'),
    meta: {
      title:'登录'
    }
  },
  //注册
  {
    path: "/register",
    name: "register",
    component:()=> import('@/views/register.vue'),
    meta: {
      title:'注册'
    }
  },
  //忘记密码
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component:()=> import('@/views/forgotPassword.vue'),
    meta: {
      title:'忘记密码'
    }
  },
  //精品课程
  {
    path: "/QualityCourse",
    name: "QualityCourse",
    component:()=> import('@/views/course/QualityCourse.vue'),
    meta: {
      title:'精品课程'
    }
  },
  //课程详情-1
  {
    path: "/CourseDetails",
    name: "CourseDetails",
    component:()=> import('@/views/course/CourseDetails'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //课程详情-2
  {
    path: "/CourseDetailsVersion_one",
    name: "CourseDetailsVersion_one",
    component:()=> import('@/views/course/CourseDetailsVersion_one'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //课程详情-3
  {
    path: "/CourseDetailsVersion_two",
    name: "CourseDetailsVersion_two",
    component:()=> import('@/views/course/CourseDetailsVersion_two'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //课程详情-4
  {
    path: "/CourseDetailsVersion_three",
    name: "CourseDetailsVersion_three",
    component:()=> import('@/views/course/CourseDetailsVersion_three'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //课程详情-5
  {
    path: "/CourseDetailsVersion_four",
    name: "CourseDetailsVersion_four",
    component:()=> import('@/views/course/CourseDetailsVersion_four'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //课程详情-6
  {
    path: "/CourseDetailsVersion_five",
    name: "CourseDetailsVersion_five",
    component:()=> import('@/views/course/CourseDetailsVersion_five'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //课程详情-7
  {
    path: "/CourseDetailsVersion_six",
    name: "CourseDetailsVersion_six",
    component:()=> import('@/views/course/CourseDetailsVersion_six'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //课程评审页面
  {
    path: "/CourseReview",
    name: "CourseReview",
    component:()=> import('@/views/course/CourseReview'),
    meta: {
      title:'精品课程-课程详情页'
    }
  },
  //全局搜索
  {
    path: "/GlobalSearch",
    name: "GlobalSearch",
    component:()=> import('@/views/Search/GlobalSearch.vue'),
    meta: {
      title:'全局搜索'
    }
  },
  //教学资源库
  {
    path: "/TeachingResource",
    name: "TeachingResource",
    component:()=> import('@/views/Teachingresource/TeachingResource.vue'),
    meta: {
      title:'教学资源库'
    }
  },
  //合作院校
  {
    path: "/PartnersSchool",
    name: "PartnersSchool",
    component:()=> import('@/views/Partners/PartnersSchool.vue'),
    meta: {
      title:'合作院校'
    }
  },
  //我的空间
  {
    path: "/MySpace",
    name: "MySpace",
    component:()=> import('@/views/mySpace/MySpace.vue'),
    meta: {
      title:'我的空间'
    }
  },
  //mocc后台管理登录
  {
    path: "/moccLogin",
    name: "moccLogin",
    component:()=> import('@/views/moccManagement/moccLogin.vue'),
    meta: {
      title:'mocc后台管理-登录'
    }
  },
  //mocc后台管理修改密码
  {
    path: "/changePassword",
    name: "changePassword",
    component:()=> import('@/views/moccManagement/changePassword.vue'),
    meta: {
      title:'mocc后台管理-修改密码'
    }
  },
  //mocc后台管理
  {
    path: "/managementHome",
    name: "managementHome",
    redirect: '/managementHome/moccHome',
    component:()=> import('@/views/moccManagement/managementHome/index.vue'),
    children: [
      //mocc后台管理首页
      {
        path: "/managementHome/moccHome",
        name: "moccHome",
        component: () => import("@/views/moccManagement/managementHome/moccHome.vue"),
        meta: {
          title:'mocc后台管理-首页'
        }
      },
      //mocc后台管理用户管理
      {
        path: "/managementHome/userManagement",
        name: "userManagement",
        component: () => import("@/views/moccManagement/managementHome/userManagement.vue"),
        meta: {
          title:'mocc后台管理-用户管理'
        }
      },
      //mocc后台管理认证管理
      {
        path: "/managementHome/autherManagement",
        name: "autherManagement",
        component: () => import("@/views/moccManagement/managementHome/autherManagement.vue"),
        meta: {
          title:'mocc后台管理-认证管理'
        }
      },
      //mocc后台管理课程管理
      {
        path: "/managementHome/courseManagement",
        name: "courseManagement",
        component: () => import("@/views/moccManagement/managementHome/courseManagement.vue"),
        meta: {
          title:'mocc后台管理-课程管理'
        }
      },
      //mocc后台管理banner管理
      {
        path: "/managementHome/bannerManagement",
        name: "bannerManagement",
        component: () => import("@/views/moccManagement/managementHome/bannerManagement.vue"),
        meta: {
          title:'mocc后台管理-banner管理'
        }
      },
      //mocc后台管理敏感词管理
      {
        path: "/managementHome/wordManagement",
        name: "wordManagement",
        component: () => import("@/views/moccManagement/managementHome/wordManagement.vue"),
        meta: {
          title:'mocc后台管理-敏感词管理'
        }
      },
    ]
  },
  //404页面
  {
    path: '**',
    name: 'not-found',
    component: ()=>import('@/views/error/notFound.vue'),
    meta: {
      title:'404'
    }
  }
]
const router = new VueRouter({
  routes,
  scrollBehavior() {
    // 始终滚动到顶部
    return { x: 0 ,y: 0,behavior: 'smooth' }
  },
});
export default router;
