import Vue from 'vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
Vue.use(ElementUI);

Vue.config.productionTip = false
import '@/js/rem'
//路由守卫动态修改页面title
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  // console.log(to.meta.rand)
  next();
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
